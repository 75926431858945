import { ButtonProps, Button as MantineButton } from "@mantine/core";
import React, { forwardRef } from "react";

function Button(
  {
    children,
    className,
    block,
    ...props
  }: {
    children: React.ReactNode;
    className?: string;
    block?: boolean;
    [key: string]: any;
  } & ButtonProps &
    React.ComponentPropsWithoutRef<"button" | "a">,
  ref: React.Ref<HTMLButtonElement | HTMLAnchorElement>
) {
  return (
    <>
      {/* @ts-ignore */}
      <MantineButton
        className={`h-auto ${block ? "w-full" : ""} ${className}`}
        /* @ts-ignore */
        ref={ref}
        {...props}
      >
        <span className="whitespace-pre-wrap leading-5">{children}</span>
      </MantineButton>
    </>
  );
}

export default forwardRef(Button);
