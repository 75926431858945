import MutedText from "@components/Various/MutedText";
import Link from "next/link";
import React from "react";

function AuthLegalDisclaimer({ action }: { action: string }) {
  return (
    <MutedText className="mt-3 text-xs">
      Durch das {action} erklären Sie sich mit unseren{" "}
      <a
        href="https://www.smarketer.de/agb-smarketer-jobs/"
        className="underline"
      >
        AGB
      </a>{" "}
      und der{" "}
      <Link href="/legal/privacy" className="underline">
        Datenschutzerklärung
      </Link>{" "}
      einverstanden.
    </MutedText>
  );
}

export default AuthLegalDisclaimer;
