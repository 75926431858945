import React from "react";
import styles from "./AiOrb.module.css";

function AiOrb({ size }: { size: number }) {
  return (
    <div
      style={{
        width: size,
        height: size,
      }}
    >
      <div
        className={styles.container}
        style={{
          // @ts-ignore
          "--size": `${size}px`,
        }}
      >
        <div className={styles.overdrawCircle} />
        <div className={styles.orbitingCircles}>
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    </div>
  );
}

export default AiOrb;
