import { Button, ButtonProps } from "@mantine/core";
import React, { forwardRef } from "react";

function BrandButton(
  {
    children,
    className,
    block = true,
    ...props
  }: {
    children: React.ReactNode;
    className?: string;
    block?: boolean;
    [key: string]: any;
  } & ButtonProps &
    React.ComponentPropsWithoutRef<"button" | "a">,
  ref: React.Ref<HTMLButtonElement | HTMLAnchorElement>
) {
  return (
    // @ts-ignore
    <Button
      className={`bg-brand-100 text-brand-800 hover:bg-brand-200 h-auto p-2 ${
        block ? "w-full" : "w-fit"
      } ${className}`}
      /* @ts-ignore */
      ref={ref}
      {...props}
    >
      <span className="whitespace-pre-wrap leading-8">{children}</span>
    </Button>
  );
}

export default forwardRef(BrandButton);
