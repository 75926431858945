import { keyframes } from "@emotion/react";
import React from "react";
import { Reveal } from "react-awesome-reveal";
import RevealAnimation from "../../Various/RevealAnimation";

const fadeAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateX(-10%);
  }

`;

function StepBox({
  index,
  children,
  highlighted,
}: {
  index: number;
  children: React.ReactNode;
  highlighted?: boolean;
}) {
  const boxContent = (
    <div className="relative flex-1 w-full overflow-hidden bg-white border border-gray-200 rounded-2xl">
      <div className="py-8 px-9">
        <div className="inline-flex items-center justify-center w-10 h-10 text-base font-bold text-white bg-brand-500 rounded-xl font-pj">
          {index}
        </div>
        <p className="mt-5 text-xl font-medium text-gray-900 font-pj">
          {children}
        </p>
      </div>
    </div>
  );

  const box = highlighted ? (
    <div className="relative flex-1 w-full">
      <div className="absolute -inset-4">
        <div
          className="w-full h-full mx-auto rotate-180 opacity-20 blur-lg filter"
          style={{
            background:
              "linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)",
          }}
        ></div>
      </div>

      {boxContent}
    </div>
  ) : (
    boxContent
  );

  return (
    <RevealAnimation
      animation={Reveal}
      keyframes={fadeAnimation}
      delay={index * 200}
      triggerOnce
    >
      {box}
    </RevealAnimation>
  );
}

export default StepBox;
