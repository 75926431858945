import Image from "next/legacy/image";
import { useTranslation } from "next-i18next";
import React from "react";

import premierPartnerImage from "@assets/images/badges/google_premier_partner_2022.svg";
import comparisonShoppingImage from "@assets/images/badges/google_comparison_shopping_partner.svg";
import msElitePartner from "@assets/images/badges/ms_elite_partner_2022.png";

function PartnerLogos() {
  const { t } = useTranslation("landing");

  return (
    <section className="py-12 bg-slate-900 sm:py-16 lg:py-20">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="xl:flex xl:items-center xl:justify-between">
          <h2 className="text-xl font-bold text-center text-slate-200 xl:text-left font-pj">
            {t("partnerLogos")}
          </h2>
          <div className="grid items-center grid-cols-1 mt-10 gap-y-6 xl:mt-0 sm:grid-cols-3 lg:gap-x-8">
            <Image
              src={premierPartnerImage}
              width={60}
              height={60}
              alt="Google Premier Partner"
            />
            <Image
              src={comparisonShoppingImage}
              width={143}
              height={36}
              alt="Google Comparison Shopping Partner"
            />
            <div className="flex justify-center items-center">
              <Image
                src={msElitePartner}
                width={110}
                height={36}
                alt="Microsoft Elite Partner"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PartnerLogos;
