import React, { FC } from "react";

/**
 * Reveal animation with proper SSR support.
 * This is a wrapper around "react-awesome-reveal" (https://github.com/morellodev/react-awesome-reveal)
 * but the library renders empty components when using SSR without JavaScript
 *
 * Usage:
 * import { Fade } from "react-awesome-reveal";
 * <RevealAnimation animation={Fade} direction="up" cascade>
 *  <h1>Hello World</h1>
 * </RevealAnimation>
 */
function RevealAnimation({
  animation: Animation,
  children,
  ...props
}: {
  animation: FC<any>;
  children: React.ReactNode;
  [key: string]: any;
}) {
  const [isSsr, setIsSsr] = React.useState(true);
  React.useEffect(() => {
    setIsSsr(false);
  }, []);

  if (isSsr) {
    return <>{children}</>;
  }

  return <Animation {...props}>{children}</Animation>;
}

export default RevealAnimation;
