import { useTranslation } from "next-i18next";
import Image from "next/legacy/image";
import React from "react";
import { Check } from "react-feather";
import logo from "@assets/images/logo.svg";
import StandaloneRegisterForm from "@components/Auth/StandaloneRegisterForm";

function FinalCTA() {
  const { t } = useTranslation("landing");
  return (
    <section className="py-12 bg-slate-50 sm:py-16 lg:py-20">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="px-8 py-10 overflow-hidden lg:px-24 rounded-3xl">
          <div className="flex justify-center mb-12">
            <Image
              src={logo}
              width={160}
              height={160}
              alt="Smarketer Jobs Logo"
            />
          </div>

          <div className="mx-auto text-center flex justify-center">
            <h2 className="text-2xl md:text-4xl font-bold text-gray-900 font-pj max-w-2xl">
              {t("finalCTA.title")}
            </h2>
          </div>
          <ul className="flex flex-col items-center justify-center mt-8 space-y-5 sm:mt-12 lg:mt-16 md:flex-row md:space-y-0 md:space-x-12">
            <li className="flex items-center text-gray-900">
              <Check />
              <span className="ml-3 font-bold font-pj">
                {t("finalCTA.isFree")}
              </span>
            </li>
            <li className="flex items-center text-gray-900">
              <Check />
              <span className="ml-3 font-bold font-pj">
                {t("finalCTA.forGoogle")}
              </span>
            </li>
            <li className="flex items-center text-gray-900">
              <Check />
              <span className="ml-3 font-bold font-pj">
                {t("finalCTA.bestPractises")}
              </span>
            </li>
            <li className="flex items-center text-gray-900">
              <Check />
              <span className="ml-3 font-bold font-pj">
                {t("finalCTA.noCreditCard")}
              </span>
            </li>
          </ul>

          <div className="max-w-lg my-6 mx-auto">
            <StandaloneRegisterForm />
          </div>
        </div>
      </div>
    </section>
  );
}

export default FinalCTA;
