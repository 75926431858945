import Image, { StaticImageData } from "next/legacy/image";
import Link from "next/link";
import React from "react";
import { ArrowRight } from "react-feather";

function Feature({
  title,
  description,
  cta,
  imageSrc,
  imageNode,
  reversed,
}: {
  title: React.ReactNode;
  description: React.ReactNode;
  cta: string;
  imageSrc?: StaticImageData;
  imageNode?: React.ReactNode;
  reversed?: boolean;
}) {
  const imageTag = (
    <div className="w-full max-w-sm mx-auto rounded-xl md:px-8">
      {imageNode ? (
        imageNode
      ) : (
        <Image
          width={400}
          height={400}
          src={imageSrc!}
          alt="Demonstration der Funktion"
          className="rounded-xl"
          placeholder="blur"
        />
      )}
    </div>
  );
  const textTag = (
    <div
      className={`text-center ${
        reversed ? "md:text-right" : "md:text-left"
      } lg:pr-16`}
    >
      <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl font-pj">
        {title}
      </h2>
      <p className="mt-4 text-lg text-gray-700 sm:mt-8 font-pj">
        {description}
      </p>
      <Link
        href="/account/auth/register"
        className="inline-flex items-center mt-8 font-bold text-gray-900 font-pj"
      >
        {cta}
        <ArrowRight />
      </Link>
    </div>
  );

  const content = reversed ? (
    <>
      {textTag}
      {imageTag}
    </>
  ) : (
    <>
      {imageTag}
      {textTag}
    </>
  );

  return (
    <section className="py-12 bg-white sm:py-16 lg:py-20">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div
          className={`
          flex ${reversed ? "flex-col-reverse" : "flex-col"}
          md:grid md:grid-cols-2
          gap-y-8 md:gap-x-16
          md:items-center
        `}
        >
          {content}
        </div>
      </div>
    </section>
  );
}

export default Feature;
