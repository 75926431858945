import { useTranslation, Trans } from "next-i18next";
import React from "react";
import StandaloneRegisterForm from "../Auth/StandaloneRegisterForm";
import HeroImage from "@assets/images/landing/3d-mockup-google.png";
import Image from "next/image";

function Hero() {
  const { t } = useTranslation("landing");

  return (
    <section className="relative py-12 sm:py-16 lg:py-20 bg-slate-100">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="grid grid-cols-1 gap-y-8 lg:items-center lg:grid-cols-2 sm:gap-y-3 gap-x-8 xl:grid-cols-5">
          <div className="text-left xl:col-span-3 lg:text-left md:px-16 lg:px-0">
            <div className="break-words">
              <h1 className="mb-3 text-4xl font-black leading-tight md:text-7xl ">
                <Trans i18nKey="hero.heading" t={t}>
                  Die{" "}
                  <span className="text-brand-500">intelligente Lösung</span>{" "}
                  für Ihr Recruiting
                </Trans>
              </h1>
              <p className="max-w-xl pt-3 mb-8 text-md lg:text-lg leading-2 lg:leading-8 text-slate-600">
                {t("hero.subheading")}
              </p>
            </div>
          </div>
          <div className="xl:col-span-2 h-full flex justify-center">
            <StandaloneRegisterForm
              fallback={
                <Image
                  height={600}
                  width={465}
                  src={HeroImage}
                  alt={t("hero.imageAlt")}
                  placeholder="blur"
                  priority
                />
              }
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
