import { useTranslation } from "next-i18next";
import Image from "next/legacy/image";
import React from "react";
import FatUnderlined from "../Various/FatUnderlined";
import teamMemberImage from "@assets/images/team-member.png";
import signatureImage from "@assets/images/jakobus-signature.png";

function CallUs() {
  const { t } = useTranslation("landing");

  return (
    <section className="overflow-hidden">
      <div className="relative pt-12 bg-slate-100 sm:pt-16 lg:pt-24 xl:pt-40">
        <div className="flex flex-col">
          <div className="block lg:hidden max-w-lg mx-auto">
            <Image
              width={507}
              height={570}
              src={teamMemberImage}
              alt={t("callUs.imageAlt")}
            />
          </div>
          <div className="py-8 bg-brand-600 lg:order-2 sm:py-12">
            <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="flex flex-col items-center sm:justify-center sm:flex-row lg:justify-start">
                <Image
                  width={110}
                  height={32}
                  src={signatureImage}
                  alt="Signatur C. Jakobus"
                />
                <div className="mt-5 sm:ml-12 sm:mt-0">
                  <p className="text-xl font-bold text-white font-pj">
                    Christopher Jakobus
                  </p>
                  <p className="text-sm font-normal font-pj text-white mt-1.5">
                    Head of Sales, Smarketer GmbH
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="flex items-end lg:order-1">
              <div className="py-12 lg:pt-0 lg:pb-24 lg:w-1/2">
                <p className="text-3xl leading-relaxed text-gray-900 font-bold">
                  <FatUnderlined>{t("callUs.title")}</FatUnderlined>
                </p>

                <p className="mt-8 text-lg font-pj">
                  {t("callUs.description")}
                </p>
                <p className="mt-4 text-lg font-pj">
                  {t("callUs.cta")}{" "}
                  <a href="tel:+49 (0) 30 920 383 4466" className="underline">
                    +49 (0) 30 920 383 4466
                  </a>
                </p>
              </div>
              <div className="absolute bottom-0 right-0 hidden w-1/2 lg:block">
                <div className="ml-10 mr-auto -mb-2">
                  <Image
                    width={507}
                    height={570}
                    src={teamMemberImage}
                    alt={t("callUs.imageAlt")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CallUs;
