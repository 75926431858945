import React from "react";

function MutedText({
  children,
  className = "",
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <p
      className={`text-sm whitespace-pre-line text-gray-400 overflow-x-hidden ${className}`}
    >
      {children}
    </p>
  );
}

export default MutedText;
