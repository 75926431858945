import React, { useCallback, useEffect } from "react";
import { useForm } from "@mantine/form";
import { TextInput } from "@mantine/core";
import { useRouter } from "next/router";
import { useRegisterMutation } from "@lib/store/slices/user";
import getFormElementError from "@lib/utils/form/getFormElementError";
import { trackEvent } from "@components/Various/Analytics/gtag";
import BrandButton from "@components/Various/BrandButton";

function RegisterForm({
  setIsLoading,
  setLoginError,
}: {
  setIsLoading?: (isLoading: boolean) => void;
  setLoginError?: (loadingError: string | undefined) => void;
}) {
  const form = useForm({
    initialValues: {
      email: "",
      password: "",
      password_confirmation: "",
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      password: (value) => (value.length < 1 ? "Please enter password" : null),
      password_confirmation: (value) =>
        value.length < 1 ? "Please enter password" : null,
    },
  });

  const [register, { isLoading, error }] = useRegisterMutation();
  const router = useRouter();

  const onRegister = useCallback(
    (values: { email: string; password: string }) => {
      setLoginError?.(undefined);

      register(values).then((data) => {
        if (!("error" in data)) {
          router.push("/account/onboarding");

          trackEvent({
            action: "register",
            category: "account",
            label: "register",
          });
        } else {
          const message = (data.error as any).data.message;
          setLoginError?.(message);
          trackEvent({
            action: "register_failed",
            category: "account",
            label: message,
          });
        }
      });
    },
    [setLoginError, register, router]
  );

  useEffect(() => {
    setIsLoading?.(isLoading);
  }, [isLoading]);

  return (
    <>
      <form onSubmit={form.onSubmit(onRegister)}>
        <div className="space-y-4 mb-6">
          <TextInput
            required
            label="Email"
            placeholder="musterperson@email.com"
            className="mb-6"
            error={getFormElementError("email", error)}
            {...form.getInputProps("email")}
          />
          <TextInput
            required
            type="password"
            label="Passwort"
            placeholder="*******"
            error={getFormElementError("password", error)}
            {...form.getInputProps("password")}
          />
          <TextInput
            required
            type="password"
            label="Passwort bestätigen"
            placeholder="*******"
            error={getFormElementError("password_confirmation", error)}
            {...form.getInputProps("password_confirmation")}
          />
        </div>
        <BrandButton type="submit" className="py-3">
          Registrieren
        </BrandButton>
      </form>
    </>
  );
}

export default RegisterForm;
