import React from "react";

function StepsSeparator() {
  return (
    <>
      <div className="block -my-1 lg:hidden">
        <svg
          className="w-4 h-auto text-gray-300"
          viewBox="0 0 16 32"
          fill="none"
          stroke="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            y1="-0.5"
            x2="18.0278"
            y2="-0.5"
            transform="matrix(0.83205 0.5547 0.5547 -0.83205 1 21)"
          />
          <line
            y1="-0.5"
            x2="18.0278"
            y2="-0.5"
            transform="matrix(0.83205 0.5547 0.5547 -0.83205 1 14)"
          />
          <line
            y1="-0.5"
            x2="18.0278"
            y2="-0.5"
            transform="matrix(0.83205 0.5547 0.5547 -0.83205 1 7)"
          />
          <line
            y1="-0.5"
            x2="18.0278"
            y2="-0.5"
            transform="matrix(0.83205 0.5547 0.5547 -0.83205 1 0)"
          />
        </svg>
      </div>
      <div className="hidden lg:block lg:-mx-2">
        <svg
          className="w-auto h-4 text-gray-300"
          viewBox="0 0 81 16"
          fill="none"
          stroke="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            y1="-0.5"
            x2="18.0278"
            y2="-0.5"
            transform="matrix(-0.5547 0.83205 0.83205 0.5547 11 1)"
          />
          <line
            y1="-0.5"
            x2="18.0278"
            y2="-0.5"
            transform="matrix(-0.5547 0.83205 0.83205 0.5547 46 1)"
          />
          <line
            y1="-0.5"
            x2="18.0278"
            y2="-0.5"
            transform="matrix(-0.5547 0.83205 0.83205 0.5547 81 1)"
          />
          <line
            y1="-0.5"
            x2="18.0278"
            y2="-0.5"
            transform="matrix(-0.5547 0.83205 0.83205 0.5547 18 1)"
          />
          <line
            y1="-0.5"
            x2="18.0278"
            y2="-0.5"
            transform="matrix(-0.5547 0.83205 0.83205 0.5547 53 1)"
          />
          <line
            y1="-0.5"
            x2="18.0278"
            y2="-0.5"
            transform="matrix(-0.5547 0.83205 0.83205 0.5547 25 1)"
          />
          <line
            y1="-0.5"
            x2="18.0278"
            y2="-0.5"
            transform="matrix(-0.5547 0.83205 0.83205 0.5547 60 1)"
          />
          <line
            y1="-0.5"
            x2="18.0278"
            y2="-0.5"
            transform="matrix(-0.5547 0.83205 0.83205 0.5547 32 1)"
          />
          <line
            y1="-0.5"
            x2="18.0278"
            y2="-0.5"
            transform="matrix(-0.5547 0.83205 0.83205 0.5547 67 1)"
          />
          <line
            y1="-0.5"
            x2="18.0278"
            y2="-0.5"
            transform="matrix(-0.5547 0.83205 0.83205 0.5547 39 1)"
          />
          <line
            y1="-0.5"
            x2="18.0278"
            y2="-0.5"
            transform="matrix(-0.5547 0.83205 0.83205 0.5547 74 1)"
          />
        </svg>
      </div>
    </>
  );
}

export default StepsSeparator;
