export default function getFormElementError(
  fieldName: string,
  errorResponse: any
): string | null {
  if (errorResponse?.data?.errors && fieldName in errorResponse?.data?.errors) {
    return errorResponse.data.errors[fieldName].join(", ");
  }

  return null;
}
