import AuthLegalDisclaimer from "@components/Auth/AuthLegalDisclaimer";
import RegisterForm from "@components/Auth/RegisterForm";
import SocialLoginButtons from "@components/Auth/SocialLoginButtons";
import { useGetCurrentUserQuery } from "@lib/store/slices/user";
import { Alert, Card, LoadingOverlay } from "@mantine/core";
import React from "react";
import { AlertTriangle } from "react-feather";

function StandaloneRegisterForm({ fallback }: { fallback?: React.ReactNode }) {
  const [loginError, setLoginError] = React.useState<string | undefined>();
  const [isLoading, setIsLoading] = React.useState(false);
  const { data: user } = useGetCurrentUserQuery();

  if (user) {
    return <>{fallback}</>;
  }

  return (
    <div>
      <LoadingOverlay visible={isLoading} />

      <Card>
        {loginError && (
          <Alert
            icon={<AlertTriangle size={16} />}
            title="Registrierung fehlgeschlagen"
            className="mb-4"
            color="red"
          >
            {loginError}
          </Alert>
        )}

        <RegisterForm
          setIsLoading={setIsLoading}
          setLoginError={setLoginError}
        />
        <SocialLoginButtons />

        <AuthLegalDisclaimer action="Registrieren" />
      </Card>
    </div>
  );
}

export default StandaloneRegisterForm;
