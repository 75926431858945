import { useTranslation } from "next-i18next";
import React from "react";
import StepBox from "./StepBox";
import StepsSeparator from "./StepsSeparator";

function Steps() {
  const { t } = useTranslation("landing");

  return (
    <section className="py-12 bg-slate-50 sm:pt-16 lg:pt-20">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl font-pj">
            {t("steps.title")}
          </h2>
          <p className="max-w-md mx-auto mt-5 text-base font-normal text-gray-600 font-pj">
            {t("steps.description")}
          </p>
        </div>

        <div className="flex flex-col items-center max-w-md mx-auto mt-8 lg:mt-20 lg:flex-row lg:max-w-none">
          <StepBox index={1}>{t("steps.register")}</StepBox>

          <StepsSeparator />

          <StepBox index={2}>{t("steps.create")}</StepBox>

          <StepsSeparator />

          <StepBox index={3} highlighted>
            {t("steps.publish")}
          </StepBox>
        </div>
      </div>

      {/* <div className="flex justify-center mt-16 md:mt-32">
        <Image
          width={656}
          height={367}
          src="/assets/images/google-jobs-cut.png"
          className="w-3/4 md:w-1/2 object-contain"
          alt="Google for Jobs auf zwei Geräten"
        />
      </div> */}
    </section>
  );
}

export default Steps;
