import React from "react";

function FatUnderlined({ children }: { children: React.ReactNode }) {
  return (
    <span
      className="underline decoration-brand-400/[.70]"
      style={{
        textDecorationSkipInk: "none",
        textUnderlineOffset: -8,
        textDecorationThickness: 8,
      }}
    >
      {children}
    </span>
  );
}

export default FatUnderlined;
