import React from "react";
import { useTranslation } from "next-i18next";
import Feature from "./Feature";

import editorImage from "@assets/images/features/editor.png";
import structuredDataImage from "@assets/images/features/structured-data-v2.png";
import performanceImage from "@assets/images/features/performance.png";
import phoneImage from "@assets/images/features/phone.png";
import atsImage from "@assets/images/features/ats.png";
import AiOrb from "@components/Various/AiPopup/AiOrb";

function Features() {
  const { t } = useTranslation("landing");

  return (
    <>
      <h2 className="text-4xl font-bold text-center bg-white pb-6 pt-12 text-slate-900">
        Warum Smarketer Jobs?
      </h2>

      <Feature
        title={t("features.google.title")}
        description={t("features.google.description")}
        cta={t("features.google.cta")}
        imageSrc={structuredDataImage}
        reversed
      />

      <Feature
        title={t("features.ai.title")}
        description={t("features.ai.description")}
        cta={t("features.ai.cta")}
        imageNode={
          <div className="flex justify-center">
            <AiOrb size={200} />
          </div>
        }
      />

      <Feature
        title={t("features.editor.title")}
        description={t("features.editor.description")}
        cta={t("features.editor.cta")}
        imageSrc={editorImage}
        reversed
      />

      <Feature
        title={t("features.performance.title")}
        description={t("features.performance.description")}
        cta={t("features.performance.cta")}
        imageSrc={performanceImage}
      />

      <Feature
        title={t("features.ats.title")}
        description={t("features.ats.description")}
        cta={t("features.ats.cta")}
        imageSrc={atsImage}
        reversed
      />

      <Feature
        title={t("features.free.title")}
        description={t("features.free.description")}
        cta={t("features.free.cta")}
        imageSrc={phoneImage}
      />
    </>
  );
}

export default Features;
