import type { NextPage } from "next";
import Head from "next/head";
import Hero from "@components/Landing/Hero";
import { useTranslation } from "next-i18next";

import Features from "@components/Landing/Features/Features";
import PartnerLogos from "@components/Landing/PartnerLogos";
import Steps from "@components/Landing/Steps/Steps";
import CallUs from "@components/Landing/CallUs";
import FinalCTA from "@components/Landing/FinalCTA";

const Home: NextPage = () => {
  const { t } = useTranslation("landing");
  return (
    <div className="bg-slate-900">
      <Head>
        <title>{`Smarketer Jobs | ${t("title")}`}</title>
      </Head>
      <Hero />
      <Features />
      <PartnerLogos />
      <Steps />
      <CallUs />
      <FinalCTA />
    </div>
  );
};

export { getStaticProps } from "@lib/utils/translationsStaticProps";

export default Home;
