import { useRouter } from "next/router";
import React from "react";
import getOriginUrl from "@lib/utils/url/getOriginUrl";
import Button from "../Various/Button";
import GoogleIcon from "../Various/icons/GoogleIcon";
import LinkedInIcon from "../Various/icons/LinkedInIcon";

function SocialLoginButtons() {
  const origin = getOriginUrl();
  const router = useRouter();

  const returnPath = (router.query.returnUrl as string) ?? "/account/jobs";
  const returnUrl = `${origin}${returnPath}`;
  const getAuthUrl = (provider: string) =>
    `${
      process.env.NEXT_PUBLIC_BACKEND_URL
    }/login/social/${provider}?return_url=${encodeURIComponent(returnUrl)}`;

  return (
    <>
      <Button
        className="bg-slate-100 text-slate-900 py-2 mt-6 hover:bg-slate-200"
        block
        component="a"
        href={getAuthUrl("google")}
      >
        <div className="flex gap-4">
          <GoogleIcon />
          Mit Google anmelden
        </div>
      </Button>
      <Button
        className="bg-slate-100 text-slate-900 py-2 mt-6 hover:bg-slate-200"
        block
        component="a"
        href={getAuthUrl("linkedin")}
      >
        <div className="flex gap-4">
          <LinkedInIcon /> Mit LinkedIn anmelden
        </div>
      </Button>
    </>
  );
}

export default SocialLoginButtons;
